import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import ZenlinkDexProtrolSvg from "./ZenlinkDEXProtocol.svg"

const FrameWorkWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const FrameWorkTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #11131a;
  line-height: 58px;
  background: linear-gradient(137deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ItemWrapper = styled.div`
  margin: 0px 20px;
  width: 290px;
  height: 369px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.04) 100%
  );
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.66);
  border-radius: 12px;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
  padding: 24px;
  /* border-image: linear-gradient(137deg, rgba(255, 255, 255, 0.3), rgba(151, 151, 151, 0.1)) 1 1; */
`

const ItemTitle = styled.div`
  width: 242px;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  line-height: 36px;
`
const ItemIcon = styled.div``
const ItemDivider = styled.div``
const ItemDesc = styled.div`
  width: 242px;
  height: 72px;
  font-size: 16px;
  color: #a4a8b3;
  line-height: 24px;
`

function FrameworkItem(props) {
  const { icon, title, desc, bottom } = props
  return (
    <ItemWrapper>
      <ItemIcon>{icon}</ItemIcon>
      <ItemDivider />
      <ItemTitle>{title}</ItemTitle>
      <ItemDesc>{desc}</ItemDesc>
    </ItemWrapper>
  )
}

export default function (props) {
  return (
    <>
      <FrameWorkWrapper>
        <FrameworkItem
          desc={
            "The underlying unified and universal cross-chain DEX Protocol."
          }
          icon={<ZenlinkDexProtrolSvg />}
          title={"Zenlink DEX Protocol"}
        />
        <FrameworkItem
          desc={
            "The underlying unified and universal cross-chain DEX Protocol."
          }
          icon={<ZenlinkDexProtrolSvg />}
          title={"Zenlink DEX Module"}
        />
        <FrameworkItem
          desc={
            "The underlying unified and universal cross-chain DEX Protocol."
          }
          icon={<ZenlinkDexProtrolSvg />}
          title={"Zenlink DEX Aggregator"}
        />
        <FrameworkItem
          desc={
            "The underlying unified and universal cross-chain DEX Protocol."
          }
          icon={<ZenlinkDexProtrolSvg />}
          title={"Zenlink DEX Composable Hub"}
        />
      </FrameWorkWrapper>
    </>
  )
}
