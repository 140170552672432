import styled from "styled-components"
import { BaseInner } from "../baseComponents"

export const StyledHeader = styled.header`
  display: flex;
  background: #1a1a1a;
  justify-content: space-around;
  position: relative;
  padding-top: 30px;
  z-index: 2;
`

export const LevelSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    padding-right: 30px;
  }
`

export const InnerSection = styled(BaseInner)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ul,
  li {
    margin: 0;
    list-style: none;
  }

  ul {
    display: inline-flex;
  }

  li:not(:first-of-type) {
    margin-left: 40px;
  }

  @media screen and (max-width: 1208px) {
    width: 1208px;
    padding: 0 160px;
  }
`
