import React, { useState } from "react"
import styled from "styled-components"
import { breakpoints } from "../const"

export const DesktopSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const DesktopSection = styled.div`
  width: 100%;
  padding: 0 40px;
  @media screen and (min-width: ${breakpoints.xl}px) {
    width: 1392px;
    /* padding: 0 0px; */
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 20px;
  }
`

export default function (props) {
  return <DesktopSectionWrapper></DesktopSectionWrapper>
}
