import styled from "styled-components"

export const BaseInner = styled.main`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 80px;

  @media screen and (max-width: 450px) {
    padding: 0 30px;
  }

  @media screen and (max-width: 1080px) {
    padding: 0 40px;
  }

  @media screen and (min-width: 1280px) {
    width: 1280px;
    padding: 0 160px;
  }
`
