import React, { useCallback, useState, useEffect } from "react"
import styles from "./index.module.css"
import styled from "styled-components"
import Logo from "./logo.svg"
import MenuIconSvg from "./menu.svg"
import Close from "./close.svg"
import HeaderLogo from "./HeaderLogo.svg"

import { Popover } from "antd"
import { SocailLinkHeader } from "../ZenlinkFoundation"
import { MediaContextProvider, Media } from "../media"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"
import Tooltip from "rc-tooltip"
import HotSvg from "./hot.svg"
import "./tooltip.css"

const LaunchAppButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  border-radius: 10px;
  padding: 1px;
`
const LaunchBugBountyButtonInner = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%); */
  background: #3c2b53;
  border-radius: 10px;
`

const LaunchAppButtonHeader = styled.div`
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  border-radius: 10px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`

const BugBountyButtonHeader = styled.div`
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 40px;
  /* background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%); */
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    rgba(242, 0, 130, 1),
    rgba(182, 32, 224, 1),
    rgba(63, 88, 240, 1)
  );
  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`
const BugBountyButtonInner = styled.div`
  width: 138px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  /* background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%); */
  border-radius: 10px;
  background: #000020;
`

const MenuIcon = styled.div`
  position: fixed;
  top: 38px;
  right: 40px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(242, 0, 130, 0.03) 0%,
    rgba(63, 88, 240, 0.09) 100%
  );
  border: 1px solid rgba(208, 210, 217, 0.15);
  border-radius: 50%;
  margin-left: 20px;
  z-index: 500;
  @media screen and (max-width: ${breakpoints.md}px) {
    right: 20px;
  }
`

const LaunchAppWrapper = styled.div`
  display: flex;
`

const HeaderDesktopWrapper = styled.div`
  position: absolute;
  display: flex;
  color: white;
  justify-content: center;
  margin-top: 40px;
  padding: 0px 40px;
  width: 100%;
  z-index: 300;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 0 20px;
  }
`

const MenuWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 260px;
  /* height: 672px; */
  display: flex;
  padding: 24px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: #3c2b53;
  border-radius: 10px;
`
const MenuSubTitle = styled.a`
  text-align: left;
  width: 100%;
  padding: 10px 10px;
  font-size: 14px;
  text-decoration: none;
  :hover {
    color: #f20082 !important;
    font-weight: bold;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.12);
    text-decoration: none;
  }
`
const MenuSubItemLink = styled.a`
  text-align: left;
  width: 100%;
  padding: 5px 10px;
  padding-left: 30px;
  font-size: 12px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  :hover {
    color: #f20082 !important;
    font-weight: bold;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.12);
    text-decoration: none;
  }
  ::before {
    content: "⋅ ";
  }
`
const MenuSubItemDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
const NavigatorUl = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  @media screen and (max-width: ${breakpoints.lg}px) {
    display: none;
  }
`
const HeaderIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`
const MenuContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: calc(100vh - 360px);
  overflow-y: scroll;
`
export function MenuPop(props) {
  const [popover, setPopover] = useState(false)
  const intl = useIntl()
  const handleClose = useCallback(() => {
    props && props.onClose && props.onClose()
  }, [props])
  return (
    <MenuWrapper>
      <HeaderIconWrapper>
        <HeaderLogo />
        <Close
          onClick={() => {
            props && props.onClose && props.onClose()
          }}
        />
      </HeaderIconWrapper>
      <MenuSubItemDivider
        style={{
          marginBottom: "16px",
        }}
      />
      <MenuContentWrapper>
        <MenuSubTitle onClick={handleClose} href="#whatiszenlnk">
          {intl.formatMessage({ id: "About" })}
        </MenuSubTitle>
        <MenuSubItemLink onClick={handleClose} href="#whatiszenlnk">
          {intl.formatMessage({ id: "What is Zenlink" })}
        </MenuSubItemLink>
        <MenuSubItemLink onClick={handleClose} href="#whyzenlink">
          {intl.formatMessage({ id: "Why Zenlink" })}
        </MenuSubItemLink>
        <MenuSubItemLink onClick={handleClose} href="#howzenlinkwork">
          {intl.formatMessage({ id: "How does Zenlink work" })}
        </MenuSubItemLink>
        <MenuSubTitle onClick={handleClose} href="#roadmap">
          {intl.formatMessage({ id: "Roadmap" })}
        </MenuSubTitle>
        <MenuSubTitle
          onClick={handleClose}
          href="https://medium.com/zenlinkpro"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: "Blog" })}
        </MenuSubTitle>
        <MenuSubTitle onClick={handleClose} href="#partner">
          {intl.formatMessage({ id: "Backers" })}
        </MenuSubTitle>
        <MenuSubItemLink onClick={handleClose} href="#partner">
          {intl.formatMessage({ id: "Partners" })}
        </MenuSubItemLink>
        <MenuSubItemLink onClick={handleClose} href="#investors">
          {intl.formatMessage({ id: "Investors" })}
        </MenuSubItemLink>
        <MenuSubTitle
          onClick={handleClose}
          href="https://wiki.zenlink.pro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {intl.formatMessage({ id: "Wiki" })}
        </MenuSubTitle>
      </MenuContentWrapper>
      <MenuSubItemDivider
        style={{
          marginTop: "16px",
        }}
      />
      <LaunchAppButton
        style={{ width: "100%" }}
        onClick={() => {
          window.open("https://app.zenlink.pro")
        }}
      >
        {intl.formatMessage({ id: "Launch APP" })}
      </LaunchAppButton>
      <LaunchAppButton
        style={{ width: "100%", marginTop: "16px" }}
        onClick={() => {
          window.open("https://immunefi.com/bounty/zenlink")
        }}
      >
        <LaunchBugBountyButtonInner>
          {intl.formatMessage({ id: "Bug Bounty" })}
        </LaunchBugBountyButtonInner>
      </LaunchAppButton>
      <MenuSubItemDivider />
      <SocailLinkHeader />
    </MenuWrapper>
  )
}

export default function () {
  const intl = useIntl()
  const [popover, setPopover] = useState(false)
  const handleScroll = useCallback(e => {
    setPopover(false)
  }, [])
  useEffect(() => {
    const handle = window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])
  return (
    <HeaderDesktopWrapper className={styles.HeaderDesktopWrapper}>
      <div className={styles.HeaderDesktop}>
        <span>
          <Logo />
        </span>
        <NavigatorUl>
          <li>
            <a href="#whatiszenlnk">{intl.formatMessage({ id: "About" })}</a>
          </li>
          <li>
            <a href="#roadmap">{intl.formatMessage({ id: "Roadmap" })}</a>
          </li>
          <li>
            <a
              href="https://medium.com/zenlinkpro"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: "Blog" })}
            </a>
          </li>
          <li>
            <a href="#partner">{intl.formatMessage({ id: "Backers" })}</a>
          </li>
          <li>
            <a
              href="https://wiki.zenlink.pro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: "Wiki" })}
            </a>
          </li>
        </NavigatorUl>
      </div>
      <BugBountyButtonHeader
        onClick={() => {
          window.open("https://immunefi.com/bounty/zenlink")
        }}
        style={{
          marginRight: "10px",
        }}
      >
        <BugBountyButtonInner>
          {intl.formatMessage({ id: "Bug Bounty" })}
        </BugBountyButtonInner>
      </BugBountyButtonHeader>
      <LaunchAppButtonHeader
        onClick={() => {
          window.open("https://app.zenlink.pro")
        }}
      >
        {intl.formatMessage({ id: "Launch APP" })}
      </LaunchAppButtonHeader>
      <LaunchAppWrapper>
        {/* <LaunchAppButtonHeader>
        LaunchApp
      </LaunchAppButtonHeader> */}
        <Tooltip
          placement="bottomRight"
          visible={popover}
          trigger={"click"}
          onVisibleChange={value => {
            setPopover(value)
          }}
          overlay={
            <MenuPop
              onClose={() => {
                setPopover(false)
              }}
            ></MenuPop>
          }
        >
          <MenuIcon>
            <MenuIconSvg />
          </MenuIcon>
        </Tooltip>
      </LaunchAppWrapper>
    </HeaderDesktopWrapper>
  )
}
