import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ title }) {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
        allFile(
          limit: 1
          filter: {
            name: { eq: "logo" }
            ext: { eq: ".png" }
            sourceInstanceName: { eq: "images" }
            relativeDirectory: { eq: "" }
          }
        ) {
          nodes {
            publicURL
          }
        }
      }
    `
  )

  return (
    <Helmet title={title} titleTemplate={`%s`}>
      <link
        rel="icon"
        href={allFile.nodes[0].publicURL}
        type="image/svg+xml"
        sizes="any"
      />
    </Helmet>
  )
}

export default SEO
