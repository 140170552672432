import React, { useState, useCallback, useRef, useEffect } from "react"
import styled from "styled-components"
import {
  DesktopSectionWrapper,
  DesktopSection,
} from "../Section/DesktopSection"
import FrameWork from "./Framework"
import LanguageSwitcher from "../Header/LanguageSwitcher"
import GithubIcon from "./githubicon.svg"
import MediumIcon from "./M.svg"
import TwiterIcon from "./twi.svg"
import DiscordIcon from "./Discord.svg"
import TelegremIcon from "./tele.svg"
import { breakpoints } from "../const"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import HotSvg from "./hot.svg"
import Tooltip from "rc-tooltip"

const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: ${breakpoints.xl}px) {
    flex-direction: column;
    margin-left: 20px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-left: 0px;
    display: block;
  }
`
const ItemListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 40px;
`
const BottomListWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${breakpoints.xl}px) {
    justify-content: space-between;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    display: block;
  }
`

const FoundationWrapper = styled.div`
  /* width: 258px; */
  margin-bottom: 40px;
  margin-right: 150px;
`
const FoundationText = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #d0d2d9;
  line-height: 48px;
`

const SwitchLanguageWrapper = styled.div``
const ItemWrapper = styled.div`
  width: 220px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    width: auto;
    min-width: 120px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width: 150px;
    min-width: auto;
  }
`
const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #a4a8b3;
  line-height: 24px;
  margin-bottom: 36px;
`
const ItemLink = styled.div`
  font-size: 14px;
  color: #fcfdff;
  line-height: 21px;
  margin-bottom: 20px;
`
const ItemLinkMail = styled.a`
  font-size: 14px;
  color: #fcfdff;
  line-height: 21px;
  margin-bottom: 20px;
  text-decoration: none;
`

const SubscribeWrapper = styled.div`
  max-width: 358px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-left: 100px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
    margin-left: 0px;
  }
`

const SubscribeTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #fcfdff;
  line-height: 24px;
  margin-bottom: 36px;
`
const SubscribeDesc = styled.div`
  font-size: 14px;
  color: #d0d2d9;
  line-height: 21px;
`

const SubscribeButton = styled.span`
  width: 150px;
  text-align: center;
  height: 44px;
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 44px;
  padding: 0px 12px;
  transform: translateX(-8px);
  cursor: pointer;
`

const SubscribeInputWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
`
const SubscribeInput = styled.input`
  background: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 10px;
  padding-right: 20px;
  color: white;
  flex-grow: 1;
  /* width: 300px; */
  @media screen and (max-width: ${breakpoints.xl}px) {
    width: auto;
  }
  :focus {
    border-color: #f20082;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px #f2008220;
  }
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    align-items: center;
  }
`
const FooterLeftWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 24px;
  margin: 24px 0px;
  @media screen and (max-width: ${breakpoints.md}px) {
    order: 1;
  }
`

const FooterRightWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`
const FooterRightWrapperHeader = styled.div`
  width: 100%;
  display: flex;
  /* margin: 24px; */
  justify-content: space-between;
`
const FooterLinkWrapper = styled.a`
  width: 32px;
  height: 32px;
  margin: 0px 20px;
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 0px;
  }
`
const FooterLinkWrapperHeader = styled.a`
  width: 24px;
  height: 24px;
  /* margin-right: 20px; */
  /* flex-grow: 1; */
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom: 0px;
  }
`
const HiringWrapper = styled.span`
  background: linear-gradient(135deg, #f20082 0%, #9c00d5 50%, #3f58f0 100%);
  border-radius: 8px;
  opacity: 0.7;
  padding: 2px 5px;
  font-size: 12px;
  /* margin: 24px; */
`

const BottomBlur = styled.div`
  width: 454px;
  height: 471px;
  background: linear-gradient(
      180deg,
      rgba(110, 0, 255, 0.22) 0%,
      rgba(0, 0, 0, 0) 100%
    )
    linear-gradient(180deg, #f20082 0%, rgba(0, 0, 0, 0) 100%);
  filter: blur(10px);
`
const ToolTipInfoWrapper = styled.div`
  margin-left: -15px;
  padding: 5px;
  border-radius: 2px;
  background-color: rgba(57, 62, 77, 0.95);
`

export default function (props) {
  const intl = useIntl()
  return (
    <div
      style={{
        background: `linear-gradient(90deg, #09072D 0%, #200F37 100%)`,
      }}
    >
      <DesktopSectionWrapper
        style={{
          background: "rgba(255, 255, 255, 0.08)",
          paddingTop: `80px`,
          paddingBottom: `80px`,
        }}
      >
        <DesktopSection>
          <SectionWrapper>
            <FoundationWrapper>
              <FoundationText>Zenlink Foundation</FoundationText>
              <SwitchLanguageWrapper>
                <LanguageSwitcher />
              </SwitchLanguageWrapper>
            </FoundationWrapper>
            <BottomListWrapper>
              <ItemListWrapper>
                <ItemWrapper>
                  <ItemTitle>
                    {intl.formatMessage({ id: "Resources" })}
                  </ItemTitle>
                  <ItemLink>
                    <a
                      href={`/${intl.locale}/whitePaper/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Whitepaper
                    </a>
                  </ItemLink>
                  <ItemLink>
                    <a
                      href="https://wiki.zenlink.pro/resources/tokenomics"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tokenomics
                    </a>
                  </ItemLink>
                  <ItemLink>
                    <a
                      href="https://wiki.zenlink.pro/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({
                        id: "Wiki",
                      })}
                    </a>
                  </ItemLink>
                  <ItemLink>
                    <a
                      href="https://medium.com/zenlinkpro"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({
                        id: "Blog",
                      })}
                    </a>
                  </ItemLink>
                  <ItemLink>
                    <a
                      href="https://github.com/zenlinkpro/zenlink-security-audit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({
                        id: "Audit Report",
                      })}
                    </a>
                  </ItemLink>
                  <ItemLink>
                    <a
                      href="https://immunefi.com/bounty/zenlink"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {intl.formatMessage({
                        id: "Bug Bounty",
                      })}
                    </a>
                  </ItemLink>
                </ItemWrapper>

                <ItemWrapper>
                  <ItemTitle>
                    {intl.formatMessage({ id: "Products" })}
                  </ItemTitle>
                  <ItemLink>
                    <a
                      href="https://app.zenlink.pro"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      DEX DAPP
                    </a>
                  </ItemLink>
                  {/* <ItemLink>
                    <a
                      href="https://slotvault.zenlink.pro"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SlotVault
                    </a>
                  </ItemLink> */}
                  <ItemLink>
                    <Tooltip
                      placement="right"
                      trigger={["hover"]}
                      overlay={
                        <ToolTipInfoWrapper>
                          {intl.formatMessage({
                            id: "Coming soon",
                          })}
                        </ToolTipInfoWrapper>
                      }
                    >
                      <a>Explorer</a>
                    </Tooltip>
                  </ItemLink>
                </ItemWrapper>

                <ItemWrapper>
                  <ItemTitle>
                    {intl.formatMessage({ id: "Foundation" })}
                  </ItemTitle>
                  <ItemLink>
                    <ItemLinkMail href="mailto: apollo@zenlink.pro">
                      apollo@zenlink.pro
                    </ItemLinkMail>
                  </ItemLink>
                  {/* <ItemLink style={{ marginBottom: "4px" }}>
                    <Tooltip
                      placement="right"
                      trigger={["hover"]}
                      overlay={
                        <ToolTipInfoWrapper>
                          {intl.formatMessage({
                            id: "Coming soon",
                          })}
                        </ToolTipInfoWrapper>
                      }
                    >
                      <a>Career</a>
                    </Tooltip>
                  </ItemLink> */}
                  {/* <ItemLink>
                    <HiringWrapper>
                      <span style={{ paddingRight: "5px" }}>We are hiring</span>
                      <HotSvg />
                    </HiringWrapper>
                  </ItemLink> */}
                </ItemWrapper>
              </ItemListWrapper>
              <SubscribeWrapper>
                <SubscribeTitle>
                  {intl.formatMessage({ id: "Let's keep in touch" })}
                </SubscribeTitle>
                <SubscribeDesc>
                  {intl.formatMessage({
                    id: "Subscribe to get the latest blog posts, news and platform announcements straight to your inbox.",
                  })}
                </SubscribeDesc>
                <SubscribeInputWrapper>
                  {/* <SubscribeInput
                    placeholder={intl.formatMessage({ id: "Input Email" })}
                  /> */}
                  <SubscribeButton
                    onClick={() => {
                      let targetLink =
                        "https://6d57dc65.sibforms.com/serve/MUIEAHFsckQ95adG8wr2P8KljuMitM5g8kVIxfy3hvSlJrUdt5Bwz28bsHUfZRkLTVZIiOwE2Mc1MhrTKNt_PRusJGF3r9sR41GO60Qv-8eVi7Mxbawm_UoFo8B8-maFSXxizfRRcJuJDwocw71a6Px8fMZuGgszNo_JsQ3j18A1--gQw7IIyrV3EU8GN4WtCuizmVdIXHv8THUB"
                      if (intl.locale === "zh") {
                        targetLink =
                          "https://6d57dc65.sibforms.com/serve/MUIEAJ1ncZ6TTxKbIJ9p6znoHiLGZTEoyS-SyGfM6zpcU5fdx6JgmD07UxfmSAW7S8so3KZDAQdrRPmjHTRMhBeFz_Z8PDpR-g-auJoQIzvbbderayjMkO8Uo2pxPD83fmBR00YVU0lDVMCzNlCKVLoGAGB-Xp5-QqqBww8H5omRGmDtYTArSn6gt0DPYwnaOi-UGRd8PQraV6Ls"
                      }
                      window.open(targetLink)
                    }}
                  >
                    {intl.formatMessage({ id: "Subscribe" })}
                  </SubscribeButton>
                </SubscribeInputWrapper>
              </SubscribeWrapper>
            </BottomListWrapper>
          </SectionWrapper>
        </DesktopSection>
      </DesktopSectionWrapper>
      <DesktopSectionWrapper
        style={{
          background: `rgba(255, 255, 255, 0.1)`,
        }}
      >
        <DesktopSection>
          <FooterWrapper>
            <FooterLeftWrapper>
              <span>©</span>
              {intl.formatMessage({
                id: "Zenlink Foundation Ltd. All rights reserved.",
              })}
            </FooterLeftWrapper>
            <FooterRightWrapper>
              <FooterLinkWrapper
                href="https://twitter.com/ZenlinkPro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwiterIcon />
              </FooterLinkWrapper>
              <FooterLinkWrapper
                href="https://discord.gg/zbVzqkHsBp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon />
              </FooterLinkWrapper>
              <FooterLinkWrapper
                href="https://medium.com/zenlinkpro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MediumIcon />
              </FooterLinkWrapper>
              <FooterLinkWrapper
                href="https://t.me/ZenlinkPro_EN"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegremIcon />
              </FooterLinkWrapper>
              <FooterLinkWrapper
                href="https://github.com/zenlinkpro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <GithubIcon />
              </FooterLinkWrapper>
            </FooterRightWrapper>
          </FooterWrapper>
        </DesktopSection>
      </DesktopSectionWrapper>
      {/* <BottomBlur/> */}
    </div>
  )
}

export function SocailLink() {
  return (
    <FooterRightWrapper>
      <FooterLinkWrapper
        href="https://twitter.com/ZenlinkPro"
        rel="noopener noreferrer"
        target="_blank"
      >
        <TwiterIcon />
      </FooterLinkWrapper>
      <FooterLinkWrapper
        href="https://discord.gg/zbVzqkHsBp"
        rel="noopener noreferrer"
        target="_blank"
      >
        <DiscordIcon />
      </FooterLinkWrapper>
      <FooterLinkWrapper
        href="https://medium.com/zenlinkpro"
        rel="noopener noreferrer"
        target="_blank"
      >
        <MediumIcon />
      </FooterLinkWrapper>
      <FooterLinkWrapper
        href="https://t.me/ZenlinkPro_EN"
        rel="noopener noreferrer"
        target="_blank"
      >
        <TelegremIcon />
      </FooterLinkWrapper>
      <FooterLinkWrapper
        href="https://github.com/zenlinkpro"
        rel="noopener noreferrer"
        target="_blank"
      >
        <GithubIcon />
      </FooterLinkWrapper>
    </FooterRightWrapper>
  )
}
export function SocailLinkHeader() {
  return (
    <FooterRightWrapperHeader>
      <FooterLinkWrapperHeader
        href="https://twitter.com/ZenlinkPro"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwiterIcon />
      </FooterLinkWrapperHeader>
      <FooterLinkWrapperHeader
        href="https://discord.gg/zbVzqkHsBp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <DiscordIcon />
      </FooterLinkWrapperHeader>
      <FooterLinkWrapperHeader
        href="https://medium.com/zenlinkpro"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MediumIcon />
      </FooterLinkWrapperHeader>
      <FooterLinkWrapperHeader
        href="https://t.me/ZenlinkPro_EN"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TelegremIcon />
      </FooterLinkWrapperHeader>
      <FooterLinkWrapperHeader
        href="https://github.com/zenlinkpro"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GithubIcon />
      </FooterLinkWrapperHeader>
    </FooterRightWrapperHeader>
  )
}
