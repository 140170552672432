import Logo from "./logo.svg"
import { InnerSection, StyledHeader, LevelSection } from "./styledComponents"
import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import LanguageSwitcher from "./LanguageSwitcher"
import styled from "styled-components"
import { MediaContextProvider, Media } from "../media"
import HeaderDesktop from "./HeaderDesktop"

export default function Header() {
  const intl = useIntl()
  const scrollToAnchor = anchorName => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName)
      if (anchorElement) {
        anchorElement.scrollIntoView()
      }
    }
  }
  const MobileStyledHeader = styled.header`
    display: flex;
    justify-content: space-around;
    position: relative;
    margin-top: 30px;
    z-index: 2;
  `

  const wrapp = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
  `

  const WrappSection = styled.div`
    display: flex;
    min-width: 375px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;

    li {
      margin: 0;
      list-style: none;
    }
  `
  const WrapA = styled.span`
    cursor: pointer;
    color: #fff;
  `

  const renderHeader = () => {
    return (
      <MediaContextProvider>
        <HeaderDesktop />
      </MediaContextProvider>
    )
  }

  return renderHeader()
}
