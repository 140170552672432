import "bootstrap/dist/css/bootstrap.min.css"
import classNames from "classnames"
import {
  changeLocale,
  injectIntl,
  IntlContextConsumer,
} from "gatsby-plugin-intl"
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import LanguageShape from "./languageShape.svg"

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)
  }

  getCode(currentLocale) {
    return currentLocale === "zh" ? "中文" : "English"
  }

  renderLanguageChoice(code, label, currentLocale) {
    return (
      <button
        key={code}
        className={classNames("dropdown-item", "link-button", {
          active: currentLocale === code,
        })}
        onClick={() => {
          window.location.href = `/${code}/`
          // changeLocale(code)
        }}
      >
        {label}
      </button>
    )
  }

  render() {
    const languageNames = [
      { code: "en", label: "English" },
      { code: "zh", label: "中文" },
    ]

    return (
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <li className="nav-item dropdown">
            <Helmet>
              <html lang={currentLocale} />
            </Helmet>

            <div
              className=" dropdown-toggle link-button"
              id="langDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LanguageShape />
              <span
                style={{
                  color: "#FFFFFF",
                  marginLeft: "5px",
                }}
              >
                {this.getCode(currentLocale)}
              </span>
            </div>
            <div className="dropdown-menu" aria-labelledby="langDropdown">
              {languageNames.map(l =>
                this.renderLanguageChoice(l.code, l.label, currentLocale)
              )}
            </div>
          </li>
        )}
      </IntlContextConsumer>
    )
  }
}

export default injectIntl(LanguageSwitcher)
